import { Link } from "gatsby"
import PropTypes from "prop-types"
import * as React from "react"
import NavLink from "./NavLink"
const logoPositivo = require("../images/logo-positivo.svg")

const Header = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  function trackEvent(name: string) {
    try {
      if (typeof window !== "undefined") {
        window.gtag("event", "click", {
          event_category: "Navbar CTA",
          event_label: name,
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <header className="fixed inset-x-0 top-0 z-50 w-full bg-white shadow-lg">
        <div className="relative">
          <div className="px-4 mx-auto max-w-8xl sm:px-6">
            <div className="flex items-center justify-between py-3 md:justify-start md:space-x-10">
              <div className="lg:w-0 lg:flex-1">
                <Link to="/" className="flex">
                  <img
                    src={logoPositivo}
                    alt="Gran Comado. Abastecemos confianza."
                    className="w-auto h-12 pr-1"
                  />
                </Link>
              </div>
              <div className="-my-2 -mr-2 md:hidden">
                <button
                  type="button"
                  onClick={() => setIsOpen(true)}
                  className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:bg-gray-100 focus:text-gray-500 focus:outline-none"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
              <nav className="hidden space-x-10 md:flex">
                <NavLink url="/">Inicio</NavLink>
                <NavLink url="/productos">Productos</NavLink>
                <NavLink url="/procesos-de-calidad">
                  Procesos de calidad
                </NavLink>
                <NavLink url="/quienes-somos">Quiénes somos</NavLink>
              </nav>
              <div className="items-center justify-end hidden space-x-8 md:flex md:flex-1 lg:w-0">
                <span className="inline-flex rounded shadow-sm">
                  <Link
                    to="/contactanos"
                    onClick={() => trackEvent("Contáctanos")}
                    className="inline-flex items-center justify-center px-3 py-2 text-sm font-semibold leading-5 text-white whitespace-no-wrap transition duration-150 ease-in-out bg-orange-500 border border-transparent rounded hover:bg-orange-400 focus:outline-none focus:border-orange-600 focus:shadow-outline-orange active:bg-orange-700"
                  >
                    Contáctanos
                  </Link>
                </span>
              </div>
            </div>
          </div>
          {/*
  <!--
    Mobile menu, show/hide based on mobile menu state.

    Entering: "duration-200 ease-out"
      From: "opacity-0 scale-95"
      To: "opacity-100 scale-100"
    Leaving: "duration-100 ease-in"
      From: "opacity-100 scale-100"
      To: "opacity-0 scale-95"
  --> */}
          {isOpen && (
            <div className="absolute inset-x-0 top-0 p-2 transition origin-top-right transform md:hidden">
              <div className="rounded-lg shadow-lg">
                <div
                  className="bg-white divide-y-2 rounded-lg shadow-xs divide-gray-50 "
                  style={{ height: "calc(100% - 16px)" }}
                >
                  <div className="px-5 pt-5 pb-6 space-y-6">
                    <div className="flex items-center justify-between">
                      <div>
                        <img
                          className="w-auto h-12"
                          src={logoPositivo}
                          alt="Logo Gran Colmado"
                        />
                      </div>
                      <div className="-mr-2">
                        <button
                          type="button"
                          onClick={() => setIsOpen(false)}
                          className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                        >
                          <svg
                            className="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div>
                      <nav className="grid row-gap-8">
                        <Link
                          to="/"
                          className="flex items-center p-3 -m-3 space-x-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50"
                        >
                          <svg
                            className="flex-shrink-0 w-6 h-6 text-green-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                            ></path>
                          </svg>
                          <div className="text-base font-medium leading-6 text-green-900">
                            Inicio
                          </div>
                        </Link>
                        <Link
                          to="/productos"
                          className="flex items-center p-3 -m-3 space-x-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="flex-shrink-0 w-6 h-6 text-green-400"
                          >
                            <path
                              fill="currentColor"
                              d="M481.7 113.55c25.4-27.43 32.47-72.08 29.75-113C482.47-1.39 431.2.29 398.9 30.43c-37.2 37.35-30.99 104.3-29.76 113 41.58 2.78 85-4.15 112.56-29.88zm-43.51 56c-16.1 3.87-33.24 6.45-52.18 6.45-6.34 0-12.69-.22-19-.64l-25.9-1.73-3.65-25.7c-.32-2.29-4.98-37.33 4.65-75.88-12.86-31.93-40.99-55.43-45.79-59.03-27.88 24.38-50.81 56.03-51.97 89.52 1.09 28.48 18.66 56.4 40.93 78.93l-41.48 41.48c1.59-5.97 2.8-12.06 3.02-18.39-.09-46.86-46.08-85.04-52.32-89.73-27.88 24.38-50.81 56.03-51.97 89.52 1.1 28.48 18.66 56.4 40.93 78.93l-41.48 41.48c1.59-5.97 2.8-12.06 3.02-18.39-.09-46.86-46.08-85.04-52.32-89.73-27.88 24.38-50.81 56.03-51.96 89.52 1.09 28.48 18.66 56.4 40.93 78.93L9.38 457.38c-12.5 12.5-12.5 32.75 0 45.25C15.63 508.88 23.81 512 32 512s16.38-3.12 22.63-9.38l72.24-72.24c22.73 22.77 50.36 40.17 79.4 41.17 33.21-1.28 65.71-24.89 89.73-52.32-17-19.44-50.28-50.61-89.52-51.97-6.62.01-12.99 1.11-19.2 2.71l41.41-41.41c22.73 22.77 50.36 40.17 79.4 41.17 33.21-1.28 65.71-24.89 89.73-52.32-17-19.44-50.28-50.61-89.52-51.97-6.62.01-12.99 1.11-19.2 2.71l41.41-41.41c22.73 22.77 50.36 40.17 79.4 41.17 33.21-1.28 65.71-24.89 89.73-52.32-12.76-14.6-34.7-35.8-61.45-46.04z"
                            />
                          </svg>

                          <div className="text-base font-medium leading-6 text-green-900">
                            Productos
                          </div>
                        </Link>
                        <Link
                          to="/procesos-de-calidad"
                          className="flex items-center p-3 -m-3 space-x-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            className="flex-shrink-0 w-6 h-6 text-green-400"
                          >
                            <path
                              fill="currentColor"
                              d="M97.12 362.63c-8.69-8.69-4.16-6.24-25.12-11.85-9.51-2.55-17.87-7.45-25.43-13.32L1.2 448.7c-4.39 10.77 3.81 22.47 15.43 22.03l52.69-2.01L105.56 507c8 8.44 22.04 5.81 26.43-4.96l52.05-127.62c-10.84 6.04-22.87 9.58-35.31 9.58-19.5 0-37.82-7.59-51.61-21.37zM382.8 448.7l-45.37-111.24c-7.56 5.88-15.92 10.77-25.43 13.32-21.07 5.64-16.45 3.18-25.12 11.85-13.79 13.78-32.12 21.37-51.62 21.37-12.44 0-24.47-3.55-35.31-9.58L252 502.04c4.39 10.77 18.44 13.4 26.43 4.96l36.25-38.28 52.69 2.01c11.62.44 19.82-11.27 15.43-22.03zM263 340c15.28-15.55 17.03-14.21 38.79-20.14 13.89-3.79 24.75-14.84 28.47-28.98 7.48-28.4 5.54-24.97 25.95-45.75 10.17-10.35 14.14-25.44 10.42-39.58-7.47-28.38-7.48-24.42 0-52.83 3.72-14.14-.25-29.23-10.42-39.58-20.41-20.78-18.47-17.36-25.95-45.75-3.72-14.14-14.58-25.19-28.47-28.98-27.88-7.61-24.52-5.62-44.95-26.41-10.17-10.35-25-14.4-38.89-10.61-27.87 7.6-23.98 7.61-51.9 0-13.89-3.79-28.72.25-38.89 10.61-20.41 20.78-17.05 18.8-44.94 26.41-13.89 3.79-24.75 14.84-28.47 28.98-7.47 28.39-5.54 24.97-25.95 45.75-10.17 10.35-14.15 25.44-10.42 39.58 7.47 28.36 7.48 24.4 0 52.82-3.72 14.14.25 29.23 10.42 39.59 20.41 20.78 18.47 17.35 25.95 45.75 3.72 14.14 14.58 25.19 28.47 28.98C104.6 325.96 106.27 325 121 340c13.23 13.47 33.84 15.88 49.74 5.82a39.676 39.676 0 0142.53 0c15.89 10.06 36.5 7.65 49.73-5.82zM97.66 175.96c0-53.03 42.24-96.02 94.34-96.02s94.34 42.99 94.34 96.02-42.24 96.02-94.34 96.02-94.34-42.99-94.34-96.02z"
                            />
                          </svg>

                          <div className="text-base font-medium leading-6 text-green-900">
                            Procesos de calidad
                          </div>
                        </Link>
                        <Link
                          to="/quienes-somos"
                          className="flex items-center p-3 -m-3 space-x-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50"
                        >
                          <svg
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                            className="flex-shrink-0 w-6 h-6 text-green-400"
                          >
                            <g>
                              <path
                                fill="currentColor"
                                d="M304 416H144a16 16 0 00-16 16v64a16 16 0 0016 16h160a16 16 0 0016-16v-64a16 16 0 00-16-16zm0-128H144a16 16 0 00-16 16v64a16 16 0 0016 16h160a16 16 0 0016-16v-64a16 16 0 00-16-16zm192 128H368a16 16 0 00-16 16v64a16 16 0 0016 16h128a16 16 0 0016-16v-64a16 16 0 00-16-16z"
                              />
                              <path
                                fill="currentColor"
                                d="M640 161.28V504a8 8 0 01-8 8h-48a8 8 0 01-8-8V208a16 16 0 00-16-16H80a16 16 0 00-16 16v296a8 8 0 01-8 8H8a8 8 0 01-8-8V161.28A48.11 48.11 0 0129.5 117l272-113.3a48.06 48.06 0 0136.9 0L610.5 117a48.11 48.11 0 0129.5 44.28z"
                              />
                            </g>
                          </svg>

                          <div className="text-base font-medium leading-6 text-green-900">
                            Quienes somos
                          </div>
                        </Link>
                      </nav>
                    </div>
                  </div>
                  <div className="px-5 py-6 space-y-6">
                    <div className="space-y-6">
                      <span className="flex w-full rounded shadow-sm">
                        <Link
                          to="/contactanos"
                          className="flex items-center justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-orange-500 border border-transparent rounded hover:bg-orange-400 focus:outline-none focus:border-orange-600 focus:shadow-outline-orange active:bg-orange-600"
                          onClick={() => trackEvent("Contacta un asesor")}
                        >
                          Contacta un asesor
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
