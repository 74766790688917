import React, { Children } from "react"
import cx from "classnames"
import { Link } from "gatsby"

interface Props {
  children: string
  url: string
}

function NavLink({ children, url }: Props) {
  return (
    <Link
      to={url}
      activeClassName="border-b-2 border-green-400"
      className="p-1 text-sm font-medium leading-4 text-green-500 transition duration-150 ease-in-out lg:text-base lg:leading-6 focus:outline-none hover:border-orange-500 hover:text-orange-500 focus:text-orange-500"
    >
      {children}
    </Link>
  )
}

export default NavLink
