/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
const logo = require("../images/logo-positivo.svg")

const defaultSchema = {
  "@context": "https://schema.org",
  "@type": "WholesaleStore",
  name: "Comercializadora Gran Colmado",
  description:
    "Comercio mayorista de granos, semillas, frutos secos e insumos para la industria de alimentos.",
  logo: logo,
  url: "https://grancolmado.com.co",
  openingHours: ["Mo-Fr 07:00-16:00", "Sa 07:00-12:30"],
  telephone: "(+57) 1 2644464",

  address: {
    "@type": "PostalAddress",
    streetAddress: "Cra. 80 # 2-51. Corabastos Bodega 7, Local 14.",
    addressLocality: "Kennedy",
    addressRegion: "Bogotá",
    postalCode: "110851",
    addressCountry: "CO",
  },
  geo: {
    "@type": "GeoCoordinates",
    latitude: 4.6291844,
    longitude: -74.1576788,
  },
  email: "contacto@grancolmado.com.co",
}

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content:
            "Gran Colmado,comercio mayorista,distribuidora,comercializadora,materias primas,granos,semillas,frutos secos,empaques,empacados",
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(defaultSchema)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
