import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

export default function Footer() {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "contact-data" } } }
      ) {
        edges {
          node {
            frontmatter {
              address
              name
              description
              email
              phone
            }
          }
        }
      }
    }
  `)

  const {
    address,
    description,
    email,
    name,
    phone,
  } = data.allMarkdownRemark.edges[0].node.frontmatter

  return (
    <footer className="w-full max-w-5xl px-4 pb-8 mx-auto bg-white">
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/3">
          <p className="text-2xl font-bold leading-8 text-green-500 font-display">
            {name}
          </p>
          <p className="text-sm leading-5 text-gray-600">{description}</p>
          <div className="mt-2 md:mt-8">
            <ul className="space-y-4 text-sm leading-4 list-none">
              <li className="flex flex-row">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  className="inline w-4 h-4 mt-1 text-gray-500 fill-current stroke-current"
                >
                  <path
                    fill="currentColor"
                    d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                  ></path>
                </svg>
                <a
                  href="https://goo.gl/maps/hry7aYGSdDTUyrVeA"
                  className="w-48 ml-2 hover:text-gray-800 focus:text-gray-800 hover:font-semibold"
                >
                  {address}
                </a>
              </li>
              <li className="flex flex-row">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="inline w-4 h-4 text-gray-500 fill-current stroke-current"
                >
                  <path
                    fill="currentColor"
                    d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"
                    className=""
                  ></path>
                </svg>
                <span className="ml-2 hover:text-gray-800 focus:text-gray-800">
                  <a
                    className="cursor-pointer hover:font-semibold hover:text-gray-900"
                    href={`tel:${phone.replace(/[\(\)\s]/g, "")}}`}
                  >
                    {phone}
                  </a>
                </span>
              </li>
              <li className="flex flex-row">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="envelope"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="inline w-4 h-4 text-gray-500 fill-current stroke-current"
                >
                  <path
                    fill="currentColor"
                    d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                    className=""
                  ></path>
                </svg>
                <span className="ml-2 hover:text-gray-800 focus:text-gray-800">
                  <a
                    className="cursor-pointer hover:font-semibold hover:text-gray-900"
                    href={`mailto:${email}`}
                  >
                    {email}
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex justify-end w-full mt-8 ml-auto md:mt-0">
          <div className="w-1/2 px-2 md:ml-4 sm:w-1/4 ">
            <h3 className="font-bold text-green-400">Compañía</h3>
            <ul className="mt-4 space-y-2 list-none">
              <li>
                <Link
                  to="/quienes-somos"
                  className="text-gray-700 hover:text-gray-900 focus:text-gray-900 "
                >
                  Quienes somos
                </Link>
              </li>
              <li>
                <Link
                  to="/contactanos"
                  className="text-gray-700 hover:text-gray-900 focus:text-gray-900 "
                >
                  Contáctanos
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-1/2 px-2 sm:w-1/4 ">
            <h3 className="font-bold text-green-400">Productos</h3>
            <ul className="mt-4 space-y-2 list-none">
              <li>
                <Link
                  to="/productos/granos"
                  className="text-gray-700 hover:text-gray-900 focus:text-gray-900 "
                >
                  Granos
                </Link>
              </li>
              <li>
                <Link
                  to="/productos/semillas"
                  className="text-gray-700 hover:text-gray-900 focus:text-gray-900 "
                >
                  Semillas
                </Link>
              </li>
              <li>
                <Link
                  to="/productos/frutos-secos"
                  className="text-gray-700 hover:text-gray-900 focus:text-gray-900 "
                >
                  Frutos secos
                </Link>
              </li>
              <li>
                <Link
                  to="/productos/empacados"
                  className="text-gray-700 hover:text-gray-900 focus:text-gray-900 "
                >
                  Empacados
                </Link>
              </li>
              <li>
                <Link
                  to="/productos/materias-primas"
                  className="text-gray-700 hover:text-gray-900 focus:text-gray-900 "
                >
                  Materias primas
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-1/2 px-2 sm:w-1/4 ">
            <h3 className="font-bold text-green-400">Redes</h3>
            <ul className="mt-4 space-y-2 list-none">
              <li>
                <a
                  href="#"
                  className="text-gray-700 hover:text-gray-900 focus:text-gray-900 "
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="text-gray-700 hover:text-gray-900 focus:text-gray-900 "
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href={`https://wa.me/${phone.replace(/[\(\)\+\s]/g, "")}`}
                  className="text-gray-700 hover:text-gray-900 focus:text-gray-900 "
                >
                  Whatsapp
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-20 text-sm leading-5 text-gray-600">
        © {new Date().getFullYear()}, Comercializadora Gran Colmado SAS. Todos
        los derechos reservados.
      </div>
    </footer>
  )
}
